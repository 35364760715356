import React from 'react';
import useClass from '../../../hooks/useClass';
import { useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { sendApplicationToLender } from '../../../store/customer/application/applicationAction';
import { useSelector } from 'react-redux';
import { openCAM } from '../../../store/utils/utils';
import CAMBoard from '../../../components/Floater/CAMBoard';

const EligibleLenders = ({row}) => {

  const { customerCode } = useParams();
  const { permission, dispatch } = useClass();
  const { activeApplicationIndex, activeLoanIndex, applicationList } = useSelector((state) => (state?.applicationAction));

  // const  handleSendToLender = () => {
  //   dispatch(sendApplicationToLender({
  //     childLoanPayload: {
  //      parentLoanUid: applicationList?.[activeApplicationIndex]?.loan?.[activeLoanIndex]?.loanUid, 
  //      lenders: [{lenderCode: row?.code}]
  //     }, 
  //     lenderPayload: {
  //       lender: row?.code, 
  //       applicationId: applicationList?.[activeApplicationIndex]?.applicationId, 
  //       customerCode
  //     }
  //   }))
  // }

  const handleSendToLender = () => {
    dispatch(openCAM()); 
  }

  return (
    <>
      {permission?.CUSTOMERS?.DETAILS?.SEND_TO_LENDER && <>
          <Button className='ms-2' size='sm' color='warning' onClick={() => {}}><i className='ri-edit-2-fill' /></Button>
          <Button className='ms-2' size='sm' color='primary' onClick={handleSendToLender}><i className='ri-send-plane-2-fill' /></Button>
        </>
      }
      <CAMBoard name={row?.name} />
    </>
  )
}

export default React.memo(EligibleLenders);
