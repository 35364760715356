import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import FieldAction from '../../../components/Dropdown/application/FieldAction';

const Data = () => {

  const { activeApplicationIndex, applicationList } = useSelector((state) => (state?.applicationAction));

  const getValues = useCallback((key) => {
    const data = applicationList?.[activeApplicationIndex]?.additionalData;
    const obj = data?.filter((obj) => (obj?.fieldKey === key))?.[0];
    if(!obj || !key){
        return '-';
    }
    if(obj?.fieldType === 'object') {
        let val = '';
        obj?.value?.forEach((data, i) => {
            if(i+1 === obj?.value?.length){
                val = val + `${data?.label}`
            } else {
                val = val + `${data?.label}, `
            }
        });
        return val;
    }
    return obj?.fieldValue;
  }, [applicationList, activeApplicationIndex]);

  return (
    <>
      <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
        <div className='data-key ms-4'>
          Product
        </div>
        <div className='data-value me-4'>{applicationList[activeApplicationIndex]?.productName}</div>
      </div>
      <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
        <div className='data-key ms-4'>
          Product Type
        </div>
        <div className='data-value me-4'>{applicationList[activeApplicationIndex]?.productType}</div>
      </div>
      <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
        <div className='data-key ms-4'>
          Status
        </div>
        <div className='data-value me-4'>{applicationList[activeApplicationIndex]?.status}</div>
      </div>
      <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
        <div className='data-key ms-4'>
          City
        </div>
        <div className='data-value me-4'>
          {getValues('city')}
          <FieldAction field={'city'}/>
        </div>
      </div>
      <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
        <div className='data-key ms-4'>
          Number Of Vehicles Required
        </div>
        <div className='data-value me-4'>
          {getValues('numberOfVehicleRequired')}
          <FieldAction field={'numberOfVehicleRequired'}/>
        </div>
      </div>
      <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
        <div className='data-key ms-4'>
          Designation
        </div>
        <div className='data-value me-4'>
          {getValues('designation')}
          <FieldAction field={'designation'}/>
        </div>
      </div>
      <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
        <div className='data-key ms-4'>
          Department
        </div>
        <div className='data-value me-4'>
          {getValues('department')}
          <FieldAction field={'department'}/>
        </div>
      </div>
      <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
        <div className='data-key ms-4'>
          Manufacterur
        </div>
        <div className='data-value me-4'>
          {getValues('manufacterur')}
        </div>
      </div>
      <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
        <div className='data-key ms-4'>
          Modal
        </div>
        <div className='data-value me-4'>
          {getValues('modal')}
        </div>
      </div>
      <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
        <div className='data-key ms-4'>
          Variant
        </div>
        <div className='data-value me-4'>
          {getValues('variant')}
        </div>
      </div>
      <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
        <div className='data-key ms-4'>
          Require assistance
        </div>
        <div className='data-value me-4'>
          {getValues('vehicleAssistance')}
        </div>
      </div>
      <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
        <div className='data-key ms-4'>
          Requested amount
        </div>
        <div className='data-value me-4'>
         ₹ {getValues('requestedAmount')}
        </div>
      </div>
    </>
  )
}

export default React.memo(Data);
