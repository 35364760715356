export const masterDataKey = {
    customerCode: 'Customer Code',
    name: 'Name',
    email: 'Email',
    mobile: 'Mobile',
    gender: 'Gender',
    createdBy: 'Created By',
    businessName: 'Business Name',
    legalName: 'Legal Name',
    entityType: 'Entity Type',
    typeOfBusiness: 'Type Of Business',
    businessCategory: 'Business Category',
    businessIncorporationYear: 'Business Incorporation Year',
    entityPan: 'Entity PAN',
    gst: 'GSTIN',
    cin: "CIN",
    udyam: 'Udyam',
    existingVehicles: 'Existing Vehicles',
    revenueLastAudited: 'Last Audited Revenue',
    pbtLastAudited: 'Last Audited PBT',
    lastProvisionalRevenue: 'Last Provisional Revenue',
    pbtLastProvisional: 'Last Provisional PBT',
    projectedRevenue: 'Projected Revenue',
    pbtProjected: 'Projected PBT',
    topDebtors: 'Top Debtors',
    topPartners: 'Top Partners',
    'financialStatementLastFY': 'Last FY financial statement',
    'financialStatementLastFY-1': `Financial Statement ${new Date().getFullYear() - 1}`,
    'financialStatementLastFY-2': `Financial Statement ${new Date().getFullYear() - 2}`,
    bankStatement: 'Bank Statement',
    loanStatementForExistingLoan: 'Existing Loan Statement',
    incomeTaxReturn: 'Income Tax Return',
    gstReturn: 'GST Return',
    sanctionLetterOfLoanLease: 'Sanction Letter of Lease Loan',
    unitEconomics: 'Unit Economics',
    shareHolding: 'Shareholding Pattern',
    companyProfile: 'Company Profile',
    memorandumOfAssociation: 'Memorandum of Association',
    partnershipDeed: 'Partnership Deed',
    balanceSheet: 'Balance Sheet',
    profitLossStatement: 'PnL statement',
    projections: 'Projections',
    revenueBreakup: 'Contract',
    rentAgreement: 'Rent Agreement',
    electrictyBill: 'Electricity Bill',
    name: 'Name',
    mobile: 'Mobile',
    din: 'DIN',
    aadhaar: 'Aadhaar',
    pan: 'PAN',
    kycStatus: 'KYC Status',
    applicationId: 'Application ID',
    productName: 'Product',
    productCode: 'Product Code',
    status: 'Status',
    numberOfVehicleRequired: 'Number Of Vehicles Required',
    manufacturer: 'Manufacturer',
    model: 'Model',
    variant: 'Variant',
    amountRequired: 'Requested Amount',
    city: 'City',
    relationshipWithNominee: 'Relation with Nominee',
    nomineeName: 'Nominee Name',
    address: 'Address',
    pincode: 'Pincode',
    gender: 'Gender',
    dateOfBirth: 'Date of Birth',
    purposeOfVisit: 'Purpose of Visit',
    productType: 'Product Type',
    rtoCode: 'RTO Code',
    registrationNumber: 'Registration Number',
    fuelType: 'Fuel Type',
    seatingCapacity: 'Seating Capacity',
    mfy: 'MFY',
    policyType: 'Policy Type',
    nilDep: 'NIL dep',
    rti: 'RTI',
    rsa: 'RSA',
    eme: 'EME',
    batteryCover: 'Battery Cover',
    cpa: 'CPA',
    imt23: 'IMT23',
    ownerDriver: 'Owner Driver',
    designation: 'Designition',
    department: 'Department',
    vehicleAssistance: 'Vehicle Assistance',
    aaMobile: 'Mobile Linked in Bank',
    Ifsc: 'IFSC',
    BankName: 'Bank Name',
    AccountNumber: 'Account Number',
    employmentType: 'Employement Type',
    houseOwnership: 'House Ownership',
    monthlyIncome: 'Monthly Income',
    loanUid: 'Loan ID',
    lenderCode: 'Lender Code',
    statusCode: 'Loan Status',
    amountRequested: 'Requested Amount',
    amountApproved: 'Approved Amount',
    amountDisbursed: 'Disbursed Amount',
    amountRepaid: 'Repaid Amount',
}

export const customerData = {
    customerCode: 'Customer Code',
    name: 'Name',
    email: 'Email',
    mobile: 'Mobile',
    gender: 'Gender',
    createdBy: 'Sourcing Entity',
    leadSource: 'Sourcing'
}

export const entityDataNumbers = {
    existingVehicles: 'Existing Vehicles',
    revenueLastAudited: 'Last Audited Revenue',
    pbtLastAudited: 'Last Audited PBT',
    lastProvisionalRevenue: 'Last Provisional Revenue',
    pbtLastProvisional: 'Last Provisional PBT',
    projectedRevenue: 'Projected Revenue',
    pbtProjected: 'Projected PBT',
}

export const entityDocumentsData = {
    'financialStatementLastFY': 'Last FY Financial Statement',
    'financialStatementLastFY-1': `Financial Statement ${new Date().getFullYear() - 1}`,
    'financialStatementLastFY-2': `Financial Statement ${new Date().getFullYear() - 2}`,
    'bankStatement': 'Bank Statement',
    'loanStatementForExistingLoan': 'Existing Loan Statement',
    'incomeTaxReturn': 'Income Tax Return',
    'gstReturn': 'GST Return',
    'sanctionLetterOfLoanLease': 'Sanction Letter of Lease Loan',
    'unitEconomics': 'Unit Economics',
    'shareHolding': 'Shareholding pattern',
    'companyProfile': 'Company Profile',
    'memorandumOfAssociation': 'Memorandum of Association',
    'partnershipDeed': 'Partnership Deed',
    'balanceSheet': 'Balance Sheet',
    'profitLossStatement': 'PnL statement',
    'projections': 'Projections',
    'revenueBreakup': 'Contract',
    'rentAgreement': 'Rent Agreement',
    'electrictyBill': 'Electricity Bill'
}

export const entityDirectorsData = {
    name: 'Name',
    mobile: 'Mobile',
    din: 'DIN',
    aadhaar: 'Aadhaar',
    pan: 'PAN',
    kycStatus: 'KYC Status'
}

export const loanData = {
    lenderCode: 'Lender Code',
    statusCode: 'Status',
    amountRepaid: 'Repaid Amount',
    createdBy: 'Created By'
}

export const reKycFieldData = {
    'cin': {
        label: 'CIN',
        code: 'cin'
    }, 
    'udyam': {
        label: 'UDYAM',
        code: 'udyam'
    }, 
    'gst': {
        label: 'GSTIN',
        code: 'gst'
    },
    'pbtProjected': {
        label: 'Projected PBT',
        code: 'pbtProjected'
    }, 
    'businessCategory': {
        label: 'Business Category',
        code: 'businessCategory'
    },
    'existingVehicles': {
        label: 'Existing Vehicles',
        code: 'existingVehicles'
    },
    'projectedRevenue': {
        label: 'Projected Revenue',
        code: 'projectedRevenue'
    },
    'pbtLastProvisional': {
        label: 'Last Provisional PBT',
        code: 'pbtLastProvisional'
    },
    'lastProvisionalRevenue': {
        label: 'Last Provisional Revenue',
        code: 'lastProvisionalRevenue'
    },
    'pbtLastAudited': {
        label: 'Last Audited PBT',
        code: 'pbtLastAudited'
    },
    'revenueLastAudited': {
        label: 'Last Audited Revenue',
        code: 'revenueLastAudited'
    },
    'aadhaar': {
        label: 'Aadhaar',
        code : 'aadhaar'
    },
    'pan': {
        label: 'PAN',
        code: 'pan'
    },
    'mobile': {
        label: 'Mobile',
        code: 'mobile'
    },
    'numberOfVehicleRequired': {
        label: 'Number of Vehicles Required',
        code: 'numberOfVehicleRequired'
    },
    'amountRequired': {
        label: 'Amount Required',
        code: 'amountRequired'
    },
    'city': {
        label: 'City',
        code: 'city'
    },
    'manufacturer': {
        label: 'Manufacturer',
        code: 'manufacturer'
    },
    'model': {
        label: 'Model',
        code: 'model'
    },
    'variant': {
        label: 'Variant',
        code: 'variant'
    },
    'relationshipWithNominee': {
        label: 'Relationship with Nominee',
        code: 'relationshipWithNominee'
    },
    'nomineeName': {
        label: 'Nominee Name',
        code: 'nomineeName'
    },
    'address': {
        label: 'Address',
        code: 'address'
    },
    'pincode': {
        label: 'Pincode',
        code: 'pincode'
    },
    'gender': {
        label: 'Gender',
        code: 'gender'
    },
    'dateOfBirth': {
        label: 'Date of Birth',
        code: 'dateOfBirth'
    },
    'designation': {
        label: 'Designation',
        code: 'designation'
    },
    'department': {
        label: 'Department',
        code: 'department'
    },
    'financialStatementLastFY': { 
        label: 'Last FY Financial Statement',
        code: 'financialStatementLastFY'
    },
    'financialStatementLastFY-1': {
        label: `Financial Statement ${new Date().getFullYear() - 1}`,
        code: 'financialStatementLastFY-1'
    },
    'financialStatementLastFY-2': {
        label: `Financial Statement ${new Date().getFullYear() - 2}`,
        code: 'financialStatementLastFY-2'
    },
    'bankStatement': {
        label: 'Bank Statement',
        code: 'bankStatement'
    }
}

export const fieldStatusConstants = {
    'VERIFIED': {
        className: 'fas fa-check-circle',
        color: 'green'
    },
    'REJECTED': {
        className: 'fas fa-times-circle',
        color: 'red'
    },
    'PENDING': {
        className: 'fas fa-question-circle',
        color: ''
    },
    'WAITING': {
        className: 'fas fa-spinner',
        color: 'yellow'
    },
    'CREATE_TASK': {
        color: 'yellow',
        className: 'fas fa-plus-circle'
    },
    'RE_KYC': {
        color: 'black',
        className: 'ri-send-plane-fill'
    }
}

export const additionalDocList = [
    {
        label: 'Existing Loan Statement',
        value: 'loanStatementForExistingLoan'
    },
    {
        label: 'Income Tax Return',
        value: 'incomeTaxReturn'
    },
    {
        label: 'GST Return',
        value: 'gstReturn'
    },
    {
        label: 'Sanction Letter of Lease Loan',
        value: 'sanctionLetterOfLoanLease'
    },
    {
        label: 'Unit Economics',
        value: 'unitEconomics'
    },
    {
        label: 'Shareholding Pattern',
        value: 'shareHolding'
    },
    {
        label: 'Company Profile',
        value: 'companyProfile'
    },
    {
        label: 'Memorandum of Association',
        value: 'memorandumOfAssociation'
    },
    {
        label: 'Partnership Deed',
        value: 'partnershipDeed'
    },
    {
        label: 'Balance Sheet',
        value: 'balanceSheet'
    },
    {
        label: 'PnL Statement',
        value: 'profitLossStatement'
    },
    {
        label: 'Projections',
        value: 'projections'
    },
    {
        label: 'Others',
        value: 'others'
    }
]

export const fieldVerificationDropdown = [
    {
        label: 'Verify',
        code: 'VERIFIED',
        color: 'green',
        className: 'fas fa-check-circle'
    },
    {
        label: 'Reject',
        code: 'REJECTED',
        color: 'red',
        className: 'fas fa-times-circle'
    },
    {
        label: 'Create Task',
        code: 'CREATE_TASK',
        color: 'yellow',
        className: 'fas fa-plus-circle'
    },
    {
        label: 'Re KYC',
        code: 'RE_KYC',
        color: 'black',
        className: 'ri-send-plane-fill'
    }
]

export const agreementType = [
    {
        label: 'MOU',
        value: 'MOU'
    },
    {
        label: 'Agreement',
        value: 'AGREEMENT'
    },
    {
        label: 'Customer Consent',
        value: 'CUSTOMER_CONSENT'
    },
    {
        label: 'CAM',
        value: 'CAM'
    }
];

export const vehicleData = {
    assetOemCode: 'Manufacturer Code',
    description:"Description",
    modelName: 'Model',
    modelYear: 'Year',
    category: 'Category',
    range: 'Range',
    batteryCapacity: 'Battery Capacity',
    energyConsuption:"Energy Consuption",
    maxSpeed:"Max Speed",
    acceleration:"Acceleration",
    warranty:"Warranty"
}

export const manufactureData = {
    code: 'Code',
    name: 'Name',
    contactNumber: 'Contact Number',
    // createdAt: 'createdAt',
    website: 'Website',
    contactEmail: 'Contact Email',
    hqAddress:"Headquarter"

}

export const batteryData = {
    make: 'Make',                        
    code: 'Battery Code',                
    capacity: 'Capacity (kWh)',
    type: 'Type',                         
    warranty: 'Warranty',                 
    assetOemCode: 'OEM Code',             
    description: 'Description',                       
  }


  export const notificationTemplateData = {
    title: 'Title',                // Corresponds to `title` from the API
    messageTemplate: 'Message Template', // Corresponds to `messageTemplate`
    notificationTypeName: 'Notification Type Name', // Corresponds to `notificationTypeId`
    link: 'Link',                  // Corresponds to `link`
    variableNames: 'Variable Names',  // Corresponds to `variableNames`
    createdAt: 'Created At',        // Corresponds to `createdAt`
    updatedAt: 'Updated At',        // Corresponds to `updatedAt`
  };
  


  export const chargerData = {
    stationId: 'Station ID',                    
    assetOemCode: 'OEM Code',                   
    code: 'Charger Code',                       
    chargerRatedCapacityId: 'Rated Capacity ID', 
    typeOfChargedId: 'Charger Type',         
    ocpiTariffRateId: 'Tariff Rate ID',         
    noOfChargers: 'Number of Chargers',         
    chargerType: 'Charger Type',                
    ratedCapacity: 'Rated Capacity (kW)',       
    serviceCharge: 'Service Charge (INR)',      
    powerType: 'Power Type',                    
    connectorWorkingStatus: 'Connector Status', 
    wkStatus: 'Working Status',                 
    tariffRate: 'Tariff Rate (INR/kWh)',        
    createdAt: 'Created At',
    updatedAt: 'Last Updated'                   
  }


  export const chargingStationData = {
    cpoId: 'CPO ID',                           
    chargerId: 'Charger ID',                    
    stationId: 'Station ID',                    
    lat: 'Latitude',                            
    lng: 'Longitude',                           
    discomId: 'Discom ID',                      
    csDisplayno: 'Station Display Number',      
    stationName: 'Station Name',                
    address: 'Address',                         
    cityName: 'City Name',                      
    pinCode: 'Pin Code',                        
    cityId: 'City ID',                          
    stateId: 'State ID',                        
    stOwner: 'Station Owner',                   
    companyName: 'Company Name',                
    mobileNumber: 'Mobile Number',              
    contactPerson: 'Contact Person',            
    amenities: 'Amenities',                     
    avgCostDiscom: 'Average Cost (Discom)',     
    openingTime: 'Opening Time',                
    closingTime: 'Closing Time',                
    isTweentyFourSeven: 'Open 24/7?',           
    wkStatus: 'Working Status',                 
    isFourWheeler: 'Supports Four-Wheeler?',    
  }
  
  
  

export const initialFieldStatusConstant = {
    'cin': 'PENDING',
    'udyam': 'PENDING',
    'gst': 'PENDING',
    'pbtProjected': 'PENDING',
    'businessCategory': 'PENDING',
    'existingVehicles': 'PENDING',
    'projectedRevenue': 'PENDING',
    'pbtLastProvisional': 'PENDING',
    'lastProvisionalRevenue': 'PENDING',
    'pbtLastAudited': 'PENDING',
    'revenueLastAudited': 'PENDING',
    'aadhaar': 'PENDING',
    'pan': 'PENDING',
    'numberOfVehicleRequired': 'PENDING',
    'amountRequired': 'PENDING',
    'city': 'PENDING',
    'manufacturer': 'PENDING',
    'model': 'PENDING',
    'variant': 'PENDING',
    'financialStatementLastFY': 'PENDING',
    'financialStatementLastFY-1': `PENDING`,
    'financialStatementLastFY-2': `PENDING`,
    'bankStatement': 'PENDING',
    'loanStatementForExistingLoan': 'PENDING',
    'incomeTaxReturn': 'PENDING',
    'gstReturn': 'PENDING',
    'sanctionLetterOfLoanLease': 'PENDING',
    'unitEconomics': 'PENDING',
    'shareHolding': 'PENDING',
    'companyProfile': 'PENDING',
    'memorandumOfAssociation': 'PENDING',
    'partnershipDeed': 'PENDING',
    'balanceSheet': 'PENDING',
    'profitLossStatement': 'PENDING',
    'projections': 'PENDING',
    'mobile': 'PENDING',
    'designation': 'PENDING',
    'department': 'PENDING'
}

export const rupeeSymbol = [
    'projectedRevenue',
    'pbtProjected',
    'pbtLastProvisional',
    'lastProvisionalRevenue',
    'pbtLastAudited',
    'revenueLastAudited',
    'monthlyIncome',
    'amountRequired',
    'amountRequested',
    'amountApproved',
    'amountDisbursed',
    'amountRepaid'
]