import React, { useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Button, Card, CardBody, CardHeader, Collapse } from 'reactstrap';

const FollowUps = () => {

  const [isOpen, setIsOpen] = useState('');

  const toggleCollapse = (id) => {
    setIsOpen(!isOpen);
  }

  const handleFollowupCreate = (e) => {

  }

  return (
    <>
      <Card>
        <CardHeader onClick={toggleCollapse}>
          <div className='d-flex gap-4 justify-content-between'>
            <div className='section-heading'>Follow-ups</div>
            <div className='form-check form-switch form-switch-md'>
              <Button
                className='me-2'
                disabled={false} 
                color='success' 
                onClick={handleFollowupCreate}
              >
                +Create
              </Button>
              <i className={isOpen ? 'ri-arrow-up-fill' : 'ri-arrow-down-fill'} />
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <Collapse isOpen={isOpen} t>
          <div className="table-responsive">
              <table className="table table-striped mb-0">
                <thead>
                  <tr>
                    <th>Task ID</th>
                    <th>Generation Date</th>
                    <th>Due Date</th>
                    <th>Type of Communication</th>
                    <th>Comment</th>
                    <th>Details of Meeting</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>TAPTASK7878U</td>
                    <td>12/12/2024</td>
                    <td>12/12/2025</td>
                    <td>Online</td>
                    <td>-</td>
                    <td>link</td>
                    <td>Open</td>
                  </tr>
                  <tr>
                    <td>TAPTASK7878U</td>
                    <td>12/12/2024</td>
                    <td>12/12/2025</td>
                    <td>Online</td>
                    <td>-</td>
                    <td>link</td>
                    <td>Open</td>
                  </tr>
                  <tr>
                    <td>TAPTASK7878U</td>
                    <td>12/12/2024</td>
                    <td>12/12/2025</td>
                    <td>Online</td>
                    <td>-</td>
                    <td>link</td>
                    <td>Open</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Collapse>
        </CardBody>
      </Card>
    </>
  )
}

export default React.memo(FollowUps);
