import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import FieldAction from '../../../components/Dropdown/application/FieldAction';
import { formatDate, formatDate3 } from '../../../utils/helper';

const EntityFinancials = () => {

    const { entityDetails } = useSelector((state) => (state?.entity));

    const getValues = useCallback((key) => {
        const data = entityDetails?.additionalData;
        const obj = data?.filter((obj) => (obj?.fieldKey === key))?.[0];
        if(!obj || !key){
            return '-';
        }
        if(obj?.fieldType === 'object') {
            let val = '';
            obj?.value?.forEach((data, i) => {
                if(i+1 === obj?.value?.length){
                    val = val + `${data?.label}`
                } else {
                    val = val + `${data?.label}, `
                }
            });
            return val;
        }
        return obj?.fieldValue;
    }, [entityDetails]);

    return (
        <>
            <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>                 
                <div className='data-key ms-4'>Legal Name</div>
                <div className='data-value me-4'>
                    {entityDetails?.legalName || '-'}
                </div>
            </div>
            <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>                 
                <div className='data-key ms-4'>Entity PAN</div>
                <div className='data-value me-4'>
                    {entityDetails?.entityPan || '-'}
                </div>
            </div>
            <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>                 
                <div className='data-key ms-4'>GSTIN</div>
                <div className='data-value me-4'>
                    {entityDetails?.gstin || '-'}
                    <FieldAction field={'gstin'} />
                </div>
            </div>
            <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>                 
                <div className='data-key ms-4'>CIN</div>
                <div className='data-value me-4'>
                    {entityDetails?.cin || '-'}
                    <FieldAction field={'cin'} />
                </div>
            </div>
            <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>                 
                <div className='data-key ms-4'>Udyam</div>
                <div className='data-value me-4'>
                    {entityDetails?.udyam || '-'}
                    <FieldAction field={'udyam'} />
                </div>
            </div>
            <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>                 
                <div className='data-key ms-4'>Entity Type</div>
                <div className='data-value me-4'>
                    {getValues('entityType')}
                    <FieldAction field={'entityType'} />
                </div>
            </div>
            <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>                 
                <div className='data-key ms-4'>Type Of Business</div>
                <div className='data-value me-4'>
                    {getValues('typeOfBusiness')}
                    <FieldAction field={'typeOfBusiness'} />
                </div>
            </div>
            <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>                 
                <div className='data-key ms-4'>Business Category</div>
                <div className='data-value me-4'>
                    {getValues('businessCategory')}
                    <FieldAction field={'businessCategory'} />
                </div>
            </div>
            <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>                 
                <div className='data-key ms-4'>Business Incorporation Year</div>
                <div className='data-value me-4'>
                    {formatDate3(entityDetails?.registrationDate)}
                </div>
            </div>
            <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>                 
                <div className='data-key ms-4'>Existing Vehicles</div>
                <div className='data-value me-4'>
                    {getValues('')}
                </div>
            </div>
            <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                <div className='data-key ms-4'>
                    Monthly Income
                </div>
                <div className='data-value me-4'>₹ {getValues('monthlyIncome')}</div>
            </div>
            <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                <div className='data-key ms-4'>
                    House Ownership
                </div>
                <div className='data-value me-4'>{getValues('houseOwnership')}</div>
            </div>
            <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                <div className='data-key ms-4'>
                    Employement Type
                </div>
              < div className='data-value me-4'>{getValues('employmentType')}</div>
            </div>
            <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                <div className='data-key ms-4'>
                    IFSC
                </div>
                <div className='data-value me-4'>{getValues('Ifsc')}</div>
            </div>
            <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                <div className='data-key ms-4'>
                    Bank Name
                </div>
                <div className='data-value me-4'>{getValues('BankName')}</div>
            </div>
            <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                <div className='data-key ms-4'>
                    Account Number
                </div>
                <div className='data-value me-4'>{getValues('AccountNumber')}</div>
            </div>
            <hr />
            <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>                 
                <div className='data-key ms-4'>Last Audited Revenue</div>
                <div className='data-value me-4'>
                    ₹ {getValues('revenueLastAudited') === '-' ? '-' : parseInt(getValues('revenueLastAudited'))?.toLocaleString()}
                    <FieldAction field={'revenueLastAudited'} />
                </div>
            </div>
            <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>                 
                <div className='data-key ms-4'>Last Audited PBT</div>
                <div className='data-value me-4'>
                    ₹ {getValues('pbtLastAudited') === '-' ? '-' : parseInt(getValues('pbtLastAudited'))?.toLocaleString()}
                    <FieldAction field={'pbtLastAudited'} />
                </div>
            </div>
            <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>                 
                <div className='data-key ms-4'>Last Provisional Revenue</div>
                <div className='data-value me-4'>
                    ₹ {getValues('lastProvisionalRevenue') === '-' ? '-' : parseInt(getValues('lastProvisionalRevenue'))?.toLocaleString()}
                    <FieldAction field={'lastProvisionalRevenue'} />
                </div>
            </div>
            <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>                 
                <div className='data-key ms-4'>Last Provisional PBT</div>
                <div className='data-value me-4'>
                    ₹ {getValues('pbtLastProvisional') === '-' ? '-' : parseInt(getValues('pbtLastProvisional'))?.toLocaleString()}
                    <FieldAction field={'pbtLastProvisional'} />
                </div>
            </div>
            <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>                 
                <div className='data-key ms-4'>Projected Revenue</div>
                <div className='data-value me-4'>
                    ₹ {getValues('projectedRevenue') === '-' ? '-' : parseInt(getValues('projectedRevenue'))?.toLocaleString()}
                    <FieldAction field={'projectedRevenue'} />
                </div>
            </div>
            <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>                 
                <div className='data-key ms-4'>Projected PBT</div>
                <div className='data-value me-4'>
                    ₹{getValues('pbtProjected') === '-' ? '-' : parseInt(getValues('pbtProjected'))?.toLocaleString()}
                    <FieldAction field={'pbtProjected'} />
                </div>
            </div>
        </>
    )
}

export default React.memo(EntityFinancials);
