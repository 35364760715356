import { createSlice } from "@reduxjs/toolkit";

const customerApplication = createSlice({
    name: 'customerApplication',
    initialState: {
        loading: false,
        error: null,
        list: [],
        totalList: 0,
        basicDetails: {},
        applicationDetails: {}
    },
    reducers: {
        getCustomerApplicationList: (state, action) => {
            state.loading = state?.list?.length === 0;
        },
        getCustomerApplicationListSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload?.data;
            state.totalList = action?.payload?.count;
        },
        getCustomerApplicationListError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        getCustomerBasicDetails: (state, action) => {
            state.loading = true;
        },
        getCustomerBasicDetailsSuccess: (state, action) => {
            state.loading = false;
            state.basicDetails = action?.payload
        },
        getCustomerBasicDetailsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
});

const entityDetils = createSlice({
    name: 'entityDetails',
    initialState: {
        loading: false,
        error: null,
        entityDetails: {},
    },
    reducers: {
        getEntityDetails: (state, action) => {
            state.loading = true;
        },
        getEntityDetailsSuccess: (state, action) => {
            state.loading = false;
            state.entityDetails = action?.payload;
        },
        getEntityDetailsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
})

const eligibleLendersOfApplication = createSlice({
    name: 'eligibleLenders',
    initialState: {
        loading: false,
        error: null,
        list: []
    },
    reducers: {
        getEligibleLendersOfApplication: (state, action) => {
            state.loading = true;
            state.error = null;
            state.list = [];
        },
        getEligibleLendersOfApplicationSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload;
        },
        getEligibleLendersOfApplicationError: (state, action) => {
            state.loading = false;
            state.error = action?.payload
        }
    }
});

const applicationLogs = createSlice({
    name: 'applicationLogs',
    initialState: {
        loading: false,
        error: null,
        data: null
    },
    reducers: {
        getApplicationLogs: (state, action) => {
            state.loading = true;
            state.error = null;
            state.data = null
        },
        getApplicationLogsSuccess: (state, action) => {
            state.loading = false;
            state.data = action?.payload;
        },
        getApplicationLogsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
})

const camReport = createSlice({
    name: 'camReport',
    initialState: {
        loading: false,
        error: null,
        data: null
    },
    reducers: {
        getCamReport: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        getCamReportSuccess: (state, action) => {
            state.loading = false;
            state.data = {...state?.data, [action?.payload?.code]: action?.payload?.data}
        },
        getCamReportError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        updateCamReport: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        updateCamReportSuccess: (state, action) => {
            state.loading = false;
        },
        updateCamReportError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
})


export const { 
    getCustomerApplicationList,
    getCustomerApplicationListSuccess,
    getCustomerApplicationListError,
    getCustomerBasicDetails,
    getCustomerBasicDetailsSuccess,
    getCustomerBasicDetailsError,
} = customerApplication.actions;

export const {
    getEntityDetails,
    getEntityDetailsSuccess,
    getEntityDetailsError,
} = entityDetils.actions;

export const {
    getEligibleLendersOfApplication,
    getEligibleLendersOfApplicationSuccess,
    getEligibleLendersOfApplicationError
} = eligibleLendersOfApplication.actions;

export const {
    getApplicationLogs,
    getApplicationLogsSuccess,
    getApplicationLogsError
} = applicationLogs.actions;

export const  {
    getCamReport,
    getCamReportSuccess,
    getCamReportError,
    updateCamReport,
    updateCamReportSuccess,
    updateCamReportError
} = camReport.actions;

export const customerReducer = {
    entity: entityDetils.reducer,
    customer: customerApplication.reducer,
    eligibleLenders: eligibleLendersOfApplication.reducer,
    applicationLogs: applicationLogs.reducer,
    camReport: camReport.reducer,
}
