import React, { useEffect } from 'react';
import { 
  Card, 
  CardBody, 
  CardHeader, 
  Container, 
  Row, 
  Col
} from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useParams } from 'react-router-dom';
import useClass from '../../hooks/useClass';
import { customerData } from '../../utils/constants';
import Applications from './applications/Applications';
import Entity from './entity/Entity';
import { useSelector } from 'react-redux';
import Accordions from '../../components/Common/Accordions';
import Payment from './payment/Payment';
import { clearREKYCTaskAdditionalDocs } from '../../store/customer/application/applicationAction';
import { getCustomerBasicDetails, getEntityDetails } from '../../store/customer/customer';

const Details = () => {

  const { dispatch } = useClass();
  const { customerCode } = useParams();
  const { basicDetails } = useSelector((state) => (state?.customer));

  useEffect(() => {
    dispatch(getCustomerBasicDetails(customerCode));
    dispatch(getEntityDetails(customerCode));
    return () => {
      dispatch(clearREKYCTaskAdditionalDocs());
    }
  }, []);

  return (
    <>
      <Row className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="customers" breadcrumbItem="Details" />
          <Row>
            <Col xl={12}>
              <Card className='common-card-style'>
                <CardHeader className='d-flex justify-content-between'>
                  <div className='section-heading'>Contact Person Details</div>
                </CardHeader>
                <CardBody>
                  <Row>
                    {Object.keys(basicDetails || {})?.map((key, index) => {
                      if(customerData[key]){
                        return (
                          <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12' key={`basic-details${index}`}>
                            <div className='data-key ms-4'>{customerData[key]}</div>
                            <div className='data-value me-4'>{key === 'createdBy' && basicDetails?.[key] === 'Admin User' ? 'Customer' :key ==="leadSource" && basicDetails?.[key] === 'Tapfin'?"Website": basicDetails?.[key]}</div>
                          </div>
                        )
                      }
                    })}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row >
            <Col xl={12}>
              <Entity />
            </Col>
          </Row>
          <Row>
            <Accordions items={[
              {
                title: 'Applications',
                content: (
                  <Applications />
                )
              },
              {
                title: 'Payments',
                content: (
                  <Payment />
                )
              }
            ]} />
          </Row>
        </Container>
      </Row>
    </>
  )
}

export default React.memo(Details);
