import React, { useState } from 'react';
import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Accordion, AccordionItem, AccordionHeader, AccordionBody } from 'reactstrap';
import useClass from '../../../hooks/useClass';
import { useSelector } from 'react-redux';
import CreditScore from '../credit-analysis/CreditScore';
import { entityDirectorsData, reKycFieldData, rupeeSymbol } from '../../../utils/constants';
import { Link } from 'react-router-dom';
import FieldAction from '../../../components/Dropdown/application/FieldAction';
import { getBureauReport } from '../../../store/reports/actions';

const Directors = () => {

    const { dispatch } = useClass();
    const { entityDetails } = useSelector((state) => (state?.entity));
    const [activeTab, setActiveTab] = useState(0);
    const [open, setOpen] = useState('');
    const [viewCreditReport, setViewCreditReport] = useState(false);
    const [viewedDirector, setViewedDirector] = useState('');

    const fetchBureauOfDirector = (dir) => {
        return () => {
            setViewedDirector(dir?.name);
            dispatch(getBureauReport(dir?.code));
            setViewCreditReport(true);
        }
    }

    const toggleTab = (tab) => {
        return () => {
            setActiveTab(tab);
        }
    }

    const toggle = (id) => {
        if (open === id) {
          setOpen('');
        } else {
          setOpen(id);
        }
    };

    const handleImageExpand = (link, label) => {
        return () => {
            window.open(link, '_blank');
            // setViewedDoc({link, label});
            // setIsOpen(true);
        }
    }

    return (
        <>
            <Row className='ps-4 pe-4'>
                <Col style={{fontWeight: 'bold'}} className='mb-2'>Directors</Col>
                <Nav tabs>
                    {entityDetails?.entityDirectors?.map((director, index) => (
                        <NavItem key={index}>
                            <NavLink 
                                style={{color: activeTab === index && 'green' }} 
                                className={`${activeTab === index && 'active'} clickable`} 
                                onClick={toggleTab(index)}
                            >
                              <i className="ri-user-line me-1 align-middle"> </i>{" "}
                              {director?.name}
                              {director?.kycStatus === 'VERIFIED' && <i style={{marginLeft: '0.4rem'}} className='fas fa-check-circle'></i>}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
                <TabContent activeTab={activeTab} className="p-3">
                    <TabPane tabId={activeTab} id="home">
                        <Row>
                            {entityDetails?.entityDirectors && <>
                                {Object.keys(entityDetails?.entityDirectors?.[activeTab] || {})?.map((key, index) => {
                                    if(entityDirectorsData[key]){
                                        return (
                                            <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12' key={index}> 
                                                <div className='data-key ms-4'>
                                                    <span>
                                                        {entityDirectorsData[key]}
                                                    </span>
                                                </div>
                                                <div className='data-value me-4'>
                                                    {rupeeSymbol?.includes(key) && '₹'}&nbsp;
                                                    {entityDetails?.entityDirectors[activeTab]?.[key] || '-'}
                                                    {reKycFieldData?.[key] && (entityDetails?.entityDirectors?.[activeTab]?.kycStatus !== 'VERIFIED') &&
                                                        <FieldAction 
                                                            data={{id: entityDetails?.entityDirectors?.[activeTab]?.din, ...entityDetails?.entityDirectors?.[activeTab]}} 
                                                            field={key}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                                {entityDetails?.entityDirectors?.[activeTab]?.kycStatus === 'VERIFIED' && <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'> 
                                    <div className='data-key ms-4'>Bureau Report</div>
                                    {entityDetails?.entityDirectors?.[activeTab]?.score ? <>
                                        <div className='d-flex'>
                                            {entityDetails?.entityDirectors?.[activeTab]?.score}&nbsp;<i onClick={fetchBureauOfDirector(entityDetails?.entityDirectors?.[activeTab])} className='ri-eye-fill' />
                                        </div>
                                    </> : <Link onClick={fetchBureauOfDirector(entityDetails?.entityDirectors?.[activeTab])} className='me-4'>Fetch</Link>}
                                </div>}
                                {entityDetails?.entityDirectors[activeTab]?.directorDoc?.map((doc, index) => (
                                    <React.Fragment key={`director-doc-${index}`}>
                                        <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                                            <Accordion open={open} toggle={toggle} className='w-100'>
                                                <AccordionItem>
                                                    <AccordionHeader targetId={'1'}>
                                                        {doc?.docType}
                                                        <span onClick={() => handleImageExpand(doc?.presignUrl)} style={{marginLeft: "50%"}}>
                                                            <i className="ri-fullscreen-line" />
                                                        </span>
                                                    </AccordionHeader>
                                                    <AccordionBody accordionId={`1`}>
                                                      <div className='w-100 mt-2'>
                                                        <iframe style={{width: "100%"}} src={doc?.presignUrl} alt="aadhaar PDF" />
                                                      </div>
                                                    </AccordionBody>
                                                </AccordionItem>
                                            </Accordion>
                                        </div>
                                    </React.Fragment>
                                ))}
                            </>}
                        </Row>
                    </TabPane>
                </TabContent>
            </Row>
            <CreditScore
                isOpen={viewCreditReport}
                setIsOpen={setViewCreditReport}
                name={viewedDirector}
            />
        </>
    )
}

export default React.memo(Directors);
