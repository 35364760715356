import JoditEditor from 'jodit-react';
import React, { useMemo, useRef, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import LoadingButton from '../Common/LoadingButton';
import { useSelector } from 'react-redux';
import useClass from '../../hooks/useClass';
import { closeCAM } from '../../store/utils/utils';

const CAMBoard = ({name, loading}) => {

  const editorCAM = useRef(null);
  const editorMail = useRef(null);
  const { dispatch, toaster } = useClass();
  const { open } = useSelector((state) => state?.modal?.CAM);
  const [value, setValue] = useState({
    cam: '',
    mail: '',
  });

  const editorConfig = useMemo(() => ({
    placeholder: '',
    height: '70svh'
  }), []);

  const handleBlur = (key, value) => {
    setValue((prev) => ({
      ...prev,
      [key]: value
    })); 
  };

  const validate = () => {
    if(
      !value.cam || 
      value.cam.trim() === ''
    ) {
      toaster.show(true, 'Please add CAM to save.');
      return;
    }
    const camHtml = decodeURIComponent(atob(value.cam));
    const mailHtml = decodeURIComponent(atob(value.mail));
    // dispatch(updateLenderCAM(data))
  }

  const toggle = () => {
    dispatch(closeCAM());
  }

  const handlePreview = () => {
    if (editorCAM.current ) {
      const cam = editorCAM.current.value;
      const previewWindow = window.open("", "Preview", "width=800,height=600");
      previewWindow.document.open();
      previewWindow.document.write(`
        <!DOCTYPE html>
        <html>
        <head>
          <title>Preview</title>
          <style>
            body { font-family: Arial, sans-serif; line-height: 1.6; padding: 20px; }
          </style>
        </head>
        <body>
          ${cam}
        </body>
        </html>
      `);
      previewWindow.document.close();
    }
  };

  return (
    <>
      <Modal
        isOpen={open}
        size='xl'
        toggle={toggle}
        centered
        // fullscreen
      >
        <ModalHeader>
          <span className='text-primary'>{name}</span>
          <button type="button" onClick={toggle} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>
        <ModalBody>
            <Row>
              <JoditEditor
                ref={editorCAM}
		          	value={value.cam}
                config={editorConfig}
		          	onBlur={(v) => handleBlur('cam', v)}
		          />
            </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handlePreview} color='info'>Preview</Button>
          <Button onClick={toggle} color='danger'>Cancel</Button>
          <LoadingButton 
            color='success' 
            buttonContent={'Save'}
            isLoading={loading}
            onClick={validate}
          />
        </ModalFooter>
      </Modal>
    </>
  )
}

export default React.memo(CAMBoard);
