import React, { useState } from 'react';
import { Modal, ButtonDropdown, DropdownItem, DropdownToggle, DropdownMenu, Spinner, Button, Collapse } from "reactstrap";
import useClass from '../../../hooks/useClass';
import { useSelector } from 'react-redux';
import { generateToken } from '../../../utils/encryption';
import { FILL_ALL_MENDETORY_FIELDS } from '../../../utils/error';
import { formatDate3 } from '../../../utils/helper';
import BasicDetails from './BasicDetails';
import EntityDetails from './EntityDetails';
import FolllowUp from './FolllowUp';

const Create = (props) => {

  const userDetails = useSelector((state) => (state?.userDetails?.details));
  const { toaster, server } = useClass();
  const {isOpen, setIsOpen} = props;
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    name: '',
    email: '',
    mobile: '',
  });
  const [followup, setFollowup] = useState(false);
  const [openButton, setOpenButton] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(0);

  const handleModelClose = () => {
    setIsOpen(false);
  }

  const handleInput = (key, value) => {
    if(key === 'deadline') {
      value = formatDate3(value);
    }
    setForm((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  const handleLeadCreation = async (redirect, sendLink) => {
    try{
      setIsSubmitted(true);
      if((!followup && validateEntityDetails()) || (followup && validateFollowUps())){
        setIsLoading(true);
        let payload = {
          name: form?.name,
          email: form?.email,
          mobile: form?.mobile,
          leadSource: 'CMS',
          status: 'INCOMPLETE',
          sendMessage: sendLink,
          details: form
        }
        const response = await server.createHotLead(payload);
        setIsLoading(false);
        handleModelClose();
        toaster.show(false, 'Hot lead created successfully');
        if(redirect){
          navigateToCos();
        } else {
          setTimeout(() => {
            window.location.reload();
          }, 1000)
        }
      }
    } catch(error){
      setIsLoading(false);
      toaster.error(error);
    }
  }

  const navigateToCos = () => {
    const cosUrl = process.env.REACT_APP_COS_URL;
    const {emailToken, mobileToken} = generateToken(form?.mobile, userDetails?.email);
    window.open(`${cosUrl}/login?e=${emailToken}&m=${mobileToken}`, '_blank');
  }

  const handleNext = () => {
    if(openCollapse === 0){
      const result = validateBasicDetails();
      if(!result){
        setIsSubmitted(true);
        return;
      }
    }
    if(openCollapse === 1) {
      const result = validateEntityDetails();
      if(!result){
        setIsSubmitted(true);
        return;
      }
    }
    setIsSubmitted(false);
    setOpenCollapse(openCollapse+1);
  }

  const handlePrev = () => {
    setIsSubmitted(false);
    setOpenCollapse(openCollapse - 1);
  }

  const handleSkip = () => {
    setFollowup(true);
    setIsSubmitted(false);
    setOpenCollapse(openCollapse+1);
  }

  const validateBasicDetails = () => {
    const fields = ['name', 'email', 'mobile'];
    return validate(fields);
  }

  const validateEntityDetails = () => {
    let fields = [
      'entityType', 
      'entityName', 
      'businessType', 
      'yearOfIncorporation',
      'serviceCities',
      'currentFYRevenue',
      'currentFYPAT',
      'lastFYRevenue',
      'lastFYPAT',
      'selectedProducts',
      'loanAmount',
      'deadline'
    ]
    if(form?.businessType?.label?.includes('Fleet')){
      fields = [...fields,
        'fleetSize',
        'evsInFleet'
      ]
    }
    const result = validate(fields);
    if(result){
      setFollowup(false);
    } else {
      setFollowup(true);
    }
    return result;
  }

  const validateFollowUps = () => {
    let fields = [
      'followupDate',
      'meetingType'
    ];
    return validate(fields);
  }

  const validate = (fields) => {
    const result = fields?.every((key) => {
      return form[key] && form[key] !== "" && form?.key?.trim() !== '';
    });
    if(!result){
      toaster.show(true, FILL_ALL_MENDETORY_FIELDS);
    }
    return result;
  }

  return (
    <>
      <Modal 
        size='lg'
        isOpen={isOpen}
        toggle={() => {setIsOpen(!isOpen)} }
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Create Lead
          </h5>
          <button type="button" onClick={handleModelClose} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Collapse
            isOpen={openCollapse === 0}
          >
            <BasicDetails handleInput={handleInput} form={form} isSubmitted={isSubmitted}/>
          </Collapse>
          <Collapse
            isOpen={openCollapse === 1}
          >
            <EntityDetails handleInput={handleInput} form={form} isSubmitted={isSubmitted} />
          </Collapse>
          <Collapse
            isOpen={openCollapse === 2}
          >
            <FolllowUp required={followup} handleInput={handleInput} form={form} isSubmitted={isSubmitted}  />
          </Collapse>
        </div>
        <div className="modal-footer">
          <Button
            disabled={openCollapse === 0}
            onClick={handlePrev}
          >
            Prev
          </Button>
          {(openCollapse !== 0) && (openCollapse !== 2) && <Button 
            color='warning'
            onClick={handleSkip}
          >
            Skip & Continue
          </Button>}
          {(openCollapse !== 2) && <Button 
            color='success' 
            onClick={handleNext}
          >
            Next
          </Button>}
          {(openCollapse === 2) && <ButtonDropdown
            isOpen={openButton}
            toggle={() => setOpenButton(!openButton)}
          >
            <DropdownToggle caret color="primary">
              Create {isLoading && <Spinner size='sm'/>}<i className="mdi mdi-chevron-down"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleLeadCreation(false, false)}>Create</DropdownItem>
              <DropdownItem onClick={() => handleLeadCreation(true, false)}>Create and Assist</DropdownItem>
              <DropdownItem onClick={() => handleLeadCreation(false, true)}>Create and send link to lead</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>}
        </div>
      </Modal>
    </>
  )
}

export default Create;
