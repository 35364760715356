import React, { useState } from 'react';
import { Modal } from 'reactstrap';
import TextInput from '../../../components/text-input/TextInput';
import LoadingButton from '../../../components/Common/LoadingButton';
import useClass from '../../../hooks/useClass';
import { useSelector } from 'react-redux';
import { updateAdditionalDocFields } from '../../../store/customer/application/applicationAction';

const NewAdditionalDoc = ({isOpen, toggle}) => {

    const {dispatch, toaster} = useClass();
    const { additionalDocList } = useSelector((state) => (state?.applicationAction));
    const [form, setForm] = useState({});

    const handleSubmit = () => {
        if(!form?.label || form?.label == ''){
            toaster.show(true, 'Please enter name of field')
            return;
        }
        if(additionalDocList?.some((obj) => (obj?.label?.toLowerCase() === form?.label?.toLowerCase()))){
            toaster.show(true, 'Document already exist.');
            return;
        }
        dispatch(updateAdditionalDocFields({field: toCamelCase(form?.label), value: {...form}, newDoc: true}));
        toggle();
        setForm({});
    }

    const handleInput = (key, value) => {
        setForm((prev) => ({
            ...prev,
            [key]: value
        }))
    }

    const toCamelCase = (str) => {
        const ans = str.trim().split(/\s+/).map((word, index) => (index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())).join('');
        return ans;
    }

    return (
    <>
        <Modal
            isOpen={isOpen}
            size={'md'}
            toggle={toggle}
            centered
        >
            <div className="modal-header">
                Add New Doc
                <button type="button" onClick={toggle} className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className='p-4'>
                <div>
                    <TextInput
                        customClass={'mb-4'}
                        name='label'
                        value={form?.label}
                        label={'Field Name'}
                        handleInput={handleInput}
                    />
                    {/* <TextInput
                        customClass={'mb-4'}
                        name='value'
                        value={form?.value}
                        label={'Field Code'}
                        handleInput={handleInput}
                    /> */}
                </div>
            </div>
            <div className="modal-footer">
                <LoadingButton
                    buttonContent={'Submit'}
                    isLoading={false}
                    onClick={handleSubmit}
                    color={'success'}
                />
            </div>
        </Modal>
    </>
  )
}

export default NewAdditionalDoc;