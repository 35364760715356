import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { fieldStatusConstants, fieldVerificationDropdown } from '../../../utils/constants';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useClass from '../../../hooks/useClass';
import { handleApplicationFieldApproveReject, updateRekycFields, updateTaskFields } from '../../../store/customer/application/applicationAction';

const FieldAction = ({data=null, field}) => {

  const { dispatch } = useClass();
  const { customerCode } = useParams();
  const { fieldStatus, reKycFields } = useSelector((state) => (state?.actionFields));
  const { activeApplicationIndex, applicationList } = useSelector((state) => (state?.applicationAction));

  const handleFieldAction = (action) => {
    if(action?.code === 'CREATE_TASK'){
      dispatch(updateTaskFields({field, code: action?.code, data}));
    } else if(action?.code === 'RE_KYC') {
      dispatch(updateRekycFields({field, code: action?.code, data}));
    } else {
      dispatch(handleApplicationFieldApproveReject({action, field, applicationId: applicationList?.[activeApplicationIndex].applicationId, customerCode}))
    }
  }

  return (
    <>
      <UncontrolledDropdown className="dropdown d-inline-block">
        <DropdownToggle className="btn btn-soft-secondary btn-sm p-0" tag="button">
          <i 
            style={{color: fieldStatusConstants?.[fieldStatus?.[field]]?.color}}
            className={reKycFields?.some((obj) => (((obj?.data?.id === data?.id) || !data) && (obj?.field === field))) ? fieldStatusConstants?.[fieldStatus?.[field]]?.className : fieldStatusConstants?.PENDING?.className} 
          />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {fieldVerificationDropdown?.map((item, index) => (
            <DropdownItem key={index} onClick={() => handleFieldAction(item)}>
              <i style={{color: item.color}} className={item.className} /> {item?.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  )
}

export default FieldAction;
