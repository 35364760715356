import React, { useCallback, useMemo } from 'react';
import TextInput from '../text-input/TextInput';
import Dropdown from '../Dropdown/Dropdown';

const DynamicFields = ({data, handleInput, form}) => {

    const memoizedHandleInput = useCallback((name, value) => {
        handleInput(name, value);
    }, []);

    const valueKey = (code) => {
        const keys = code?.split(' ');
        const value = keys?.reduce((obj, key) => obj?.[key], form);
        return value;
    }

    const dynamic = useMemo(() => {
        switch(data?.type){
            case 'dropdown':
                return <>
                    <Dropdown
                        customClass={'w-100'}
                        name={data?.code}
                        label={data?.label}
                        placeholder={data?.label}
                        options={data?.options}
                        handleInput={memoizedHandleInput}
                        value={valueKey(data?.code)}
                        isClearable={data?.isMulti}
                        required={true}
                        isMulti={data?.isMulti}
                    />
                </>
            case 'binary':
                return <>
    
                </>
            default:
                return (
                    <TextInput
                        customClass={'w-100'}
                        value={valueKey(data?.code)}
                        name={data?.code}
                        label={data?.label}
                        handleInput={memoizedHandleInput}
                        type={data?.type}
                        placeholder={data?.label}
                        required={true}
                    />
                )
        }
    }, [data, form])

    return (
        <div className='d-flex align-items-center'>
            {dynamic}
        </div>
    )
}

export default React.memo(DynamicFields);
