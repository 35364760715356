import React from 'react';
import { Button, Card, CardBody, CardHeader, Modal, ModalBody, ModalHeader, Row, Col, CardTitle } from 'reactstrap';
import { useSelector } from 'react-redux';
import BankTransactions from './BankTransactions';

const BankStatement = ({isOpen, setIsOpen}) => {

    const { bankStatement } = useSelector((state) => (state?.reports));

    return (
        <>
            <Modal
                isOpen={isOpen}
                size='xl'
                toggle={() => setIsOpen(!isOpen)}
                centered
                fullscreen
            >
                <ModalHeader>
                    <span className='text-primary'>Bank Statement</span>
                    <button type="button" onClick={() => setIsOpen(!isOpen)} className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody>
                    {bankStatement?.doc ? <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(bankStatement?.doc)}`} style={{width: '100%', height: '100%'}}/> : <div className='text-center'>Doc not found</div>}
                    {/* <Row>
                        <Col lg={6} md={6} sm={12}>
                            <Card className='common-card-style'>
                                <CardHeader>
                                    <div className='section-heading'>Personal Details</div>
                                </CardHeader>
                                <CardBody>
                                    <div className='row'>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'>CUID</div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'>Name</div>
                                            <div className='data-value'>DONDAPATI LAVANYA</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'>DOB</div>
                                            <div className='data-value'>8th oct 1998</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'>Mobile No.</div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'>PAN No.</div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'>Email</div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'>Address</div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className='common-card-style'>
                                <CardHeader>
                                    <div className='section-heading'>Intent to Pay(Lifetime)</div>
                                </CardHeader>
                                <CardBody>
                                    <div className='row'>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'> Count of Negative Events </div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'> Time Since Last Default (in days) </div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'> DR/CR Ratio </div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'> FOIR </div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'> Average Monthly Income </div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'> Customer Type </div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <Card className='common-card-style'>
                                <CardHeader>
                                    <div className='section-heading'>Account Details</div>
                                </CardHeader>
                                <CardBody>
                                    <div className='row'>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'>Account Number</div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'>Bank</div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'>IFSC</div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'>Holding Nature</div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'>Nominee</div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'>Account Type</div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'>Account Status</div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'>Account Opening Date</div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'>Consent Start-date</div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'>Consent End-date</div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'>cKYC Compliance</div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'>Data Vintage (in months)</div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'>No. of Transactions</div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'>Current Balance</div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='data-key'>Avg. Bank Balance</div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className='common-card-style'>
                                <CardHeader>
                                    <div className='section-heading'>Notable Aspects(Count of transactions)</div>
                                </CardHeader>
                                <CardBody>
                                    <div className='row'>
                                        <div className='d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'> Smoking/Paan </div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'> Alcohol Intoxication </div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'> Gambling/Fantasy </div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'> Insurance Claim </div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'> Rent Received </div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'> Cryptocurrency Exposure </div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className='common-card-style'>
                                <CardHeader>
                                    <div className='section-heading'>EMI estimator</div>
                                </CardHeader>
                                <CardTitle>
                                    <div className='row p-4'>
                                        <div className='d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'> Net ABB </div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div><div className='d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'> Total Months of ABB Policy Met (Lifetime) </div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'> Total Months of ABB Policy Met (6 months) </div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'> Total Months of ABB Policy Not Met (Lifetime) </div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>
                                        <div className='d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'> Suitable EMI Dates </div>
                                            <div className='data-value'>37fdfb14-b096-11ef-9e3e-66b0bb1091d3</div>
                                        </div>

                                    </div>
                                </CardTitle>
                                <CardBody>
                                    <div className="table-responsive">
                                        <table className="table mb-0 table-bordered">
                                          <thead>
                                            <tr>
                                                <th className='text-center' rowSpan={2}>Months</th>
                                                <th className='text-center' colSpan={1}>Total Credits</th>
                                                <th className='text-center' colSpan={1}>Total Credits</th>
                                                <th className='text-center' colSpan={2}>No. of Credits</th>
                                                <th className='text-center' colSpan={2}>No. of Cheque Bounce</th>
                                                <th className='text-center' colSpan={6}>Balance as on Date</th>
                                                <th className='text-center'>Net ABB</th>
                                                <th className='text-center'>ABB Policy Met with criteria for proposed EMI</th>
                                            </tr>
                                            <tr>
                                                <th>(with internal transfer and loan disbursal)</th>
                                                <th>(without internal transfer and loan disbursal)</th>
                                                <th>(with internal transfer and loan disbursal)</th>
                                                <th>(without internal transfer and loan disbursal)</th>
                                                <th>Inward</th>
                                                <th>Outward</th>
                                                <th>1</th>
                                                <th>5</th>
                                                <th>10</th>
                                                <th>15</th>
                                                <th>25</th>
                                                <th>30</th>
                                                <th></th>
                                                <th>1.5</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                            </tr>
                                            <tr>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                            </tr>
                                            <tr>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className='common-card-style'>
                                <CardHeader>
                                    <div className='section-heading'>Obligations</div>
                                </CardHeader>
                                <CardBody>
                                <div className="table-responsive">
                                    <table className="table mb-0 table-bordered">
                                      <thead>
                                        <tr>
                                          <th className='text-center' rowSpan={2}>SrNo</th>
                                          <th className='text-center' rowSpan={2}>Lender</th>
                                          <th className='text-center' rowSpan={2}>Obligation Type</th>
                                          <th className='text-center'>Obligations</th>
                                          <th className='text-center'>Repaid from Bank Account</th>
                                          <th className='text-center' colSpan={12}>Months</th>
                                        </tr>
                                        <tr>
                                            <th>Monthly Avg Obligation</th>
                                            <th>Bank (A/c No)</th>
                                            <th>2022-11</th>
                                            <th>2022-12</th>
                                            <th>2023-01</th>
                                            <th>2023-02</th>
                                            <th>2023-03</th>
                                            <th>2023-04</th>
                                            <th>2023-05</th>
                                            <th>2023-06</th>
                                            <th>2023-07</th>
                                            <th>2023-08</th>
                                            <th>2023-09</th>
                                            <th>2023-10</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>Mark</td>
                                          <td>Otto</td>
                                          <td>@mdo</td>
                                          <td>Mark</td>
                                          <td>Otto</td>
                                          <td>@mdo</td>
                                          <td>Mark</td>
                                          <td>Otto</td>
                                          <td>@mdo</td>
                                          <td>Mark</td>
                                          <td>Otto</td>
                                          <td>@mdo</td>
                                          <td>Mark</td>
                                          <td>Otto</td>
                                          <td>@mdo</td>
                                          <td>Mark</td>
                                          <td>Otto</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className='common-card-style'>
                                <CardHeader>
                                    <div className='section-heading'>Loan Disbursment</div>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive">
                                        <table className="table table-bordered mb-0">
                                          <thead>
                                            <tr>
                                              <th> SrNo </th>
                                              <th> accNumber </th>
                                              <th> transactionTimestamp </th>
                                              <th> narration </th>
                                              <th> type </th>
                                              <th> amount </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <th scope="row">1</th>
                                              <td>Mark</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                            </tr>
                                            <tr>
                                              <th scope="row">2</th>
                                              <td>Jacob</td>
                                              <td>Thornton</td>
                                              <td>@fat</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                            </tr>
                                            <tr>
                                              <th scope="row">3</th>
                                              <td>Larry</td>
                                              <td>the Bird</td>
                                              <td>@twitter</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className='common-card-style'>
                                <CardHeader>
                                    <div className='section-heading'>EMI Transactions</div>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive">
                                        <table className="table table-bordered mb-0">
                                          <thead>
                                            <tr>
                                              <th> SrNo </th>
                                              <th> accNumber </th>
                                              <th> transactionTimestamp </th>
                                              <th> narration </th>
                                              <th> type </th>
                                              <th> amount </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <th scope="row">1</th>
                                              <td>Mark</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                            </tr>
                                            <tr>
                                              <th scope="row">2</th>
                                              <td>Jacob</td>
                                              <td>Thornton</td>
                                              <td>@fat</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                            </tr>
                                            <tr>
                                              <th scope="row">3</th>
                                              <td>Larry</td>
                                              <td>the Bird</td>
                                              <td>@twitter</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className='common-card-style'>
                                <CardHeader>
                                    <div className='section-heading'>Credit Card Transactions</div>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive">
                                        <table className="table table-bordered mb-0">
                                          <thead>
                                            <tr>
                                              <th> SrNo </th>
                                              <th> accNumber </th>
                                              <th> transactionTimestamp </th>
                                              <th> narration </th>
                                              <th> type </th>
                                              <th> amount </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <th scope="row">1</th>
                                              <td>Mark</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                            </tr>
                                            <tr>
                                              <th scope="row">2</th>
                                              <td>Jacob</td>
                                              <td>Thornton</td>
                                              <td>@fat</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                            </tr>
                                            <tr>
                                              <th scope="row">3</th>
                                              <td>Larry</td>
                                              <td>the Bird</td>
                                              <td>@twitter</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className='common-card-style'>
                                <CardHeader>
                                    <div className='section-heading'>Risk Indicators</div>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive">
                                        <table className="table table-bordered mb-0">
                                          <thead>
                                            <tr>
                                              <th> Risk Indicators </th>
                                              <th> Identified? </th>
                                              <th> Count of Indicators </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>@mdo</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                            </tr>
                                            <tr>
                                              <td>@fat</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                            </tr>
                                            <tr>
                                              <td>@twitter</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <BankTransactions />
                    <Row>
                        <Col>
                            <Card className='common-card-style'>
                                <CardHeader>
                                <div className='section-heading'>IRREGULAR SALARY CREDITS</div>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive">
                                        <table className="table table-bordered mb-0">
                                          <thead>
                                            <tr>
                                              <th> accNumber </th>
                                              <th> transactionTimestamp </th>
                                              <th> narration </th>
                                              <th> type </th>
                                              <th> amount </th>
                                              <th> currentBalance </th>
                                              <th> instrument </th>
                                              <th> purpose </th>
                                              <th> subCategory </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>Mark</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                              <td>@mdo</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                              <td>@mdo</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className='common-card-style'>
                                <CardHeader>
                                <div className='section-heading'>VARYING SALARY AMOUNTS</div>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive">
                                        <table className="table table-bordered mb-0">
                                          <thead>
                                            <tr>
                                              <th> accNumber </th>
                                              <th> transactionTimestamp </th>
                                              <th> narration </th>
                                              <th> type </th>
                                              <th> amount </th>
                                              <th> currentBalance </th>
                                              <th> instrument </th>
                                              <th> purpose </th>
                                              <th> subCategory </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>Mark</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                              <td>@mdo</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                              <td>@mdo</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className='common-card-style'>
                                <CardHeader>
                                <div className='section-heading'>AMOUNT BALANCE MISMATCH</div>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive">
                                        <table className="table table-bordered mb-0">
                                          <thead>
                                            <tr>
                                              <th> accNumber </th>
                                              <th> transactionTimestamp </th>
                                              <th> narration </th>
                                              <th> type </th>
                                              <th> amount </th>
                                              <th> currentBalance </th>
                                              <th> instrument </th>
                                              <th> purpose </th>
                                              <th> subCategory </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>Mark</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                              <td>@mdo</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                              <td>@mdo</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className='common-card-style'>
                                <CardHeader>
                                <div className='section-heading'>EVERGREENING</div>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive">
                                        <table className="table table-bordered mb-0">
                                          <thead>
                                            <tr>
                                              <th> accNumber </th>
                                              <th> transactionTimestamp </th>
                                              <th> narration </th>
                                              <th> type </th>
                                              <th> amount </th>
                                              <th> currentBalance </th>
                                              <th> instrument </th>
                                              <th> purpose </th>
                                              <th> subCategory </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>Mark</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                              <td>@mdo</td>
                                              <td>Otto</td>
                                              <td>@mdo</td>
                                              <td>@mdo</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className='common-card-style'>
                                <CardHeader>
                                <div className='section-heading'>EOD Balance</div>
                                </CardHeader>
                                <CardBody>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row> */}
                    {/* <Row>
                        <Col>
                            <Card className='common-card-style'>
                                <CardHeader>
                                <div className='section-heading'>Inflow</div>
                                </CardHeader>
                                <CardBody>
                                    
                                </CardBody>
                            </Card>
                        </Col>
                        <Col>
                            <Card className='common-card-style'>
                                <CardHeader>
                                <div className='section-heading'>Outflow</div>
                                </CardHeader>
                                <CardBody>
                                    
                                </CardBody>
                            </Card>
                        </Col>
                    </Row> */}
                </ModalBody>
                {/* <ModalFooter>
                    <Button onClick={() => setIsOpen(!isOpen)}>Close</Button>
                </ModalFooter> */}
            </Modal>
        </>
    )
}

export default React.memo(BankStatement);
