import React, { useState } from "react";
import { Modal } from "reactstrap";
import useClass from "../../../hooks/useClass";
import TextInput from "../../../components/text-input/TextInput";
import Dropdown from "../../../components/Dropdown/Dropdown";
import LoadingButton from "../../../components/Common/LoadingButton";
import { FILL_ALL_MENDETORY_FIELDS } from "../../../utils/error";

const CreateProduct = (props) => {

  const { adminServer, toaster, navigate } = useClass();
  const { isOpen, setIsOpen } = props;
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: ''
  });

  const handleModelClose = () => {
    setIsOpen(false);
  };

  const handleInput = (key, value) => {
    setForm((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  const createProductScheme = async () => {
    try {
      setLoading(true);
      if(validateForm(form)){
        const response = await adminServer.createScheme(form);
        setLoading(false);
        setIsOpen(false);
        navigate(`/product_scheme/details/${response?.data?.data?.code}`);
      } else {
        throw new Error(FILL_ALL_MENDETORY_FIELDS);
      }
    } catch (error) {
      setLoading(false);
      toaster.error(error);
    }
  };

  const validateForm = (payload) => {
    const result = Object.keys(payload).every((key) => {
      return form.hasOwnProperty(key) && form[key] !== "";
    });
    return result;
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen);
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Create Product Scheme
          </h5>
          <button
            type="button"
            onClick={handleModelClose}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div>
            <TextInput
              name={'name'}
              label={'Scheme Name'}
              value={form?.name}
              required={true}
              handleInput={handleInput}
            />
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={handleModelClose}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <LoadingButton
            buttonContent={"Create"}
            isLoading={loading}
            onClick={createProductScheme}
            color={"success"}
          />
        </div>
      </Modal>
    </>
  );
};

export default CreateProduct;
