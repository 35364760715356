import React, { useEffect, useState } from 'react';
import { 
    Card, 
    CardBody, 
    CardHeader,
    Row,
    Spinner,
} from 'reactstrap';
import DocModal from '../../../components/Document-Viewer/DocModal';
import { useSelector } from 'react-redux';
import EntityFinancials from './EntityFinancials';
import DebtorDetails from './DebtorDetails';
import CustomerDetails from './CustomerDetails';
import Directors from './Directors';
import AdditionalDocs from './AdditionalDocs';
import EntityDocs from './EntityDocs';
import useClass from '../../../hooks/useClass';
import { getBankStatementReport } from '../../../store/reports/actions';
import { useParams } from 'react-router-dom';

const Entity = () => {

    const { dispatch } = useClass();
    const { customerCode } = useParams();
    const { entityDetails, loading } = useSelector((state) => (state?.entity));
    const [isOpen, setIsOpen] = useState(false);
    const [viewedDoc, setViewedDoc] = useState({});

    useEffect(() => {
        dispatch(getBankStatementReport(customerCode));
    }, [])

    if(loading) {
        return <div className='text-center'>
            <Spinner />
        </div>
    }
    
    return (
        <>
            <Card>
                <CardHeader>
                    <div className='section-heading'>Entity: {entityDetails?.businessName}</div>
                </CardHeader>
                <CardBody>
                    <Row>
                        <EntityFinancials />
                        <hr />
                        <EntityDocs />
                        <AdditionalDocs />
                    </Row>
                    <hr />
                    <Directors />
                    <DebtorDetails />
                    <CustomerDetails />
                </CardBody>
            </Card>
            <DocModal 
                isOpen={isOpen} 
                setIsOpen={setIsOpen}
                size={'xl'}
                label={viewedDoc?.label}
                link={viewedDoc?.link}
            />
        </>
    )
}

export default React.memo(Entity);
