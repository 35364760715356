import React, { useState } from 'react';
import { Modal } from "reactstrap";
import LoadingButton from '../../components/Common/LoadingButton';
import useClass from '../../hooks/useClass';
import TextInput from '../../components/text-input/TextInput';
import Dropdown from '../../components/Dropdown/Dropdown';
import { FILL_ALL_MENDETORY_FIELDS } from '../../utils/error';

const Create = (props) => {

  const { toaster, server, permission, navigate } = useClass();
  const {isOpen, setIsOpen} = props;
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    name: '',
    description: '',
    type: ''
  });

  const handleModelClose = () => {
    setIsOpen(false);
  }

  const handleInput = (key, value) => {
    setForm((prev) => ({
        ...prev,
        [key]: value
    }))
  }

  const handleTaskCreation = async () => {
    try{
      setIsLoading(true);
      if(validateForm()){
        const payload = {
          name: form?.name,
          description: form?.description,
          type: form?.type?.value
        }
        const response = await server.createTask(payload);
        setIsLoading(false);
        handleModelClose();
        if(permission?.TASK?.DETAILS?.VIEW_TASK_DETAILS){
          navigate(`/task/details/${response?.data?.newTask?.id}`);
          toaster.show(false, 'Task created successfully');
        } else {
          toaster.show(false, 'Task created successfully');
        }
      } else {
        toaster.show(true, FILL_ALL_MENDETORY_FIELDS);
      }
    } catch(error){
      setIsLoading(false);
      toaster.error(error);
    }
  }

  const validateForm = () => {
    const result = Object.keys(form).every((key) => {
        return form.hasOwnProperty(key) && form[key] !== "";
    });
    return result;
  }

  return (
    <>
        <Modal isOpen={isOpen}
          toggle={() => {setIsOpen(!isOpen)} }
          centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                  Create Task
                </h5>
                <button type="button" onClick={handleModelClose} className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
              <div>
                <TextInput 
                  label={'Name'}
                  required={true}
                  name={'name'}
                  value={form?.name}
                  handleInput={handleInput}
                  type={'text'}
                />
              </div>
              <div className='mt-4'>
                <TextInput 
                  label={'Description'}
                  required={true}
                  name={'description'}
                  value={form?.description}
                  handleInput={handleInput}
                  type={'text'}
                />
              </div>
              <div className='mt-4'>
                <Dropdown
                  label={'Type'}
                  name={'type'}
                  handleInput={handleInput}
                  options={[{label: 'Customer Visit', value: 'CUSTOMER_VISIT'},{label: 'Verification', value: 'VERIFICATION'}]}
                  required={true}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" onClick={handleModelClose} className="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
              <LoadingButton
                buttonContent={'Create'}
                isLoading={isLoading}
                onClick={handleTaskCreation}
                color={'success'}
              />
            </div>
        </Modal>
    </>
  )
}

export default Create;
