import { createSlice } from "@reduxjs/toolkit";


let permissions = []
const userDetails = createSlice({
  name: 'userDetails',
  initialState: {
    details: {}
  },
  reducers: {
    updateUserDetails: (state, action) => {
      state.details = action?.payload;
    }
  }
});

const userPermissions = createSlice({
  name: "userPermissions",
  initialState: {
    permissions: permissions
  },
  reducers: {
    updateUserPermissions: (state, action) => {
      state.permissions = action?.payload
    }
  }
})

const permissionsKeyPair = createSlice({
  name: "permissionsPair",
  initialState: {
    permissions: {}
  },
  reducers: {
    updatePermissionsPair: (state, action) => {
      state.permissions = action?.payload
    }
  }
})

const task = createSlice({
    name: 'taskList',
    initialState: {
      list: [],
      totalList: 0,
      loading: false,
      error: null
    },
    reducers: {
      getTaskList: (state, action) => {
        state.loading = state?.list?.length === 0;
      },
      getTaskListSuccess: (state, action) => {
        state.loading = false;
        state.list = action?.payload?.data;
        state.totalList = action?.payload?.count;
      },
      getTaskListFailure: (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      },
      updateTask: (state, action) => {
        state.loading = true;
      },
      updateTaskSuccess:(state, action) => {
        state.loading = false;
      },
      updateTaskFailure: (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      }
     
    }
});



const hotLead = createSlice({
  name: 'hotLead',
  initialState: {
    list: [],
    totalList: 0,
    loading: false,
    error: null,
    details: null
  },
  reducers: {
    getHotLeadList: (state, action) => {
      state.loading = state?.list?.length === 0;
    },
    getHotLeadListSuccess: (state, action) => {
      state.loading = false;
      state.list = action?.payload?.data;
      state.totalList = action?.payload?.count;
    },
    getHotLeadListError: (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    },
    getHotLeadDetails: (state, action) => {
      state.details = null;
      state.loading = true;
      state.error = null;
    },
    getHotLeadDetailsSuccess: (state, action) => {
      state.details = action?.payload;
      state.loading = false;
    },
    getHotLeadDetailsError: (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    },
    updateHotLeadDetails: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    updateHotLeadDetailsSuccess: (state, action) => {
      state.loading = false;
    },
    updateHotLeadDetailsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
})

const notification = createSlice({
  name: 'notification',
  initialState: {
    notifications: [],
    totalList: 0,
    loading: false,
    error: null
  },
  reducers: {
    getNotifications: (state, action) => {
      state.loading = true;
      state.error = null
    },
    getNotificationsSuccess: (state, action) => {
      state.loading = false;
      state.notifications = [...state.notifications, ...action.payload.data];
      state.totalList = action?.payload?.count;
    },
    getNotificationsError: (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    }
  }
})

// actions
export const  { updateUserDetails } = userDetails?.actions;
export const { updateUserPermissions } = userPermissions?.actions;
export const { updatePermissionsPair } = permissionsKeyPair?.actions;
export const { 
  getTaskList, 
  getTaskListSuccess, 
  getTaskListFailure,
  updateTaskFailure,
  updateTaskSuccess,
  updateTask
} = task?.actions;

export const { 
  getHotLeadList,
  getHotLeadListSuccess,
  getHotLeadListError,
  getHotLeadDetails,
  getHotLeadDetailsError,
  getHotLeadDetailsSuccess,
  updateHotLeadDetails,
  updateHotLeadDetailsError,
  updateHotLeadDetailsSuccess
} = hotLead?.actions;

export const {
  getNotifications,
  getNotificationsSuccess,
  getNotificationsError,
  addNotification
} = notification?.actions;

// reducers
export const userReducer = {
  task: task?.reducer,
  hotLead: hotLead?.reducer,
  notification: notification?.reducer,
  userDetails: userDetails?.reducer,
  userPermissions: userPermissions?.reducer,
  permissionPair: permissionsKeyPair?.reducer
}

