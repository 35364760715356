import { createSlice } from "@reduxjs/toolkit";

const modal = createSlice({
    name: 'modelUtils',
    initialState: {
      CAM: {
        open: false
      }
    },
    reducers: {
      openCAM: (state, action) => {
        state.CAM.open = true;
      },
      closeCAM: (state, action) => {
        state.CAM.open = false;
      }
    }
});

export const { 
    openCAM,
    closeCAM
} = modal.actions;

// reducers
export const utilsReducer = {
    modal: modal.reducer
}