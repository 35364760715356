import React, { useCallback, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import LoadingButton from '../../Common/LoadingButton';
import useClass from '../../../hooks/useClass';
import { 
  toggleUpdateData, 
  updateApplicationData, 
  updateFieldValue 
} from '../../../store/customer/application/Assesment';
import { useSelector } from 'react-redux';
import TextInput from '../../text-input/TextInput';
import { CHANGE_ANY_ENTRY } from '../../../utils/error';
import { useParams } from 'react-router-dom';

const UpdateApplicationData = () => {

  const { dispatch, toaster } = useClass();
  const { customerCode } = useParams();
  const { open, loading, fields } = useSelector((state) => (state?.editData));
  const { entityDetails } = useSelector((state) => (state?.entity));
  const { activeApplicationIndex, applicationList } = useSelector((state) => (state?.applicationAction));

  const handleInput = (value, index) => {
    if(/^0/.test(value)){
      dispatch(updateFieldValue({value: 0, index}));
      return;
    }
    dispatch(updateFieldValue({value, index}));
  }

  const handleUpdate = () => {
    console.log(fields, 'testing fields');
    let data = [];
    fields?.forEach((field) => {
      if(field.fieldType === 'string' && (field.value && field.value.trim() !== '')){
        data.push({
          fieldKey: field.code,
          fieldValue: field.value,
          fieldType: field.fieldType
        })
      }
    });

    if(data.length === 0){
      toaster.show(true, CHANGE_ANY_ENTRY);
      return;
    }
    const payload = {
      customerCode,
      "applicationData":[
        {
          "applicationId": applicationList?.[activeApplicationIndex]?.applicationId,
          data
        }
      ]
    }
    dispatch(updateApplicationData(payload));
  }

  const toggleModal = () => {
    dispatch(toggleUpdateData());
  }

  const getValues = useCallback((key) => {
    const data = entityDetails?.additionalData;
    const obj = data?.filter((obj) => (obj?.fieldKey === key))?.[0];
    if(!obj || !key){
      return '-';
    }
    if(obj?.fieldType === 'object') {
      let val = '';
      obj?.value?.forEach((data, i) => {
        if(i+1 === obj?.value?.length){
          val = val + `${data?.label}`
        } else {
          val = val + `${data?.label}, `
        }
      });
      return val;
    }
    return obj?.fieldValue;
  }, [entityDetails]);

  return (
    <>
      <Modal
        isOpen={open}
        size='lg'
        toggle={toggleModal}
        centered
      >
        <ModalHeader>
          Update(Only changed fields will be updated)
          <button type="button" onClick={toggleModal} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>
        <ModalBody>
          {fields?.map((field, index) => (
            <div key={`app-edit-data-${index}`} className='mb-2 row'>
              <div className='col-4'>
                {field.label}
              </div>
              <div className='col-4'>
                {field.preInputText} {getValues(field.code)}
              </div>
              <div className='col-4'>
                <TextInput
                  customClass={'w-100'}
                  name={field.code}
                  value={field.value}
                  handleInput={(key, value) => handleInput(value, index)}
                  type={'number'}
                  inputMode={'numeric'}
                  maxLength={15}
                />
              </div>
            </div>
          ))}
        </ModalBody>
        <ModalFooter>
          <LoadingButton
            isLoading={loading}
            buttonContent={'Update'}
            color={'success'}
            onClick={handleUpdate}
          />
        </ModalFooter>
      </Modal>
    </>
  )
}

export default React.memo(UpdateApplicationData);
