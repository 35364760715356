import React, { useCallback, useEffect, useState } from 'react';
import { Badge, Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import {lenderConfigFields} from '../../constants/constants';

const LenderMappingBRE = ({data}) => {

    console.log(data, 'lender bre data')
    const constFields = lenderConfigFields({});
    const dummy = [
        {
            code: 'typeOfProduct',
            statusL: 'PASS',
            label: '',
            value: '',

        },
        {
            code: 'rate_of_interest',
            status: 'PASS',
            label: '',
            value: '2 %',
            go: {
                min: '3',
                max: '5'
            }
        },
        {
            code: 'processing_fee',
            status: 'PASS',
            label: '',
            value: '2000',
            go: {
                min: '1000',
                max: '10000'
            }
        },
        {
            code: 'loan_loan_amount',
            status: 'FAIL',
            label: '',
            value: 80000,
            go: {
                min: 100000
            },
            deviation: {
                min: 10000
            }
        },
        {
            code: 'loan_loan_tenure',
            status: 'PASS',
            label: '',
            value: 24,
            go: {
                min: 15,
                max: 30
            }
        },
        {
            code: 'customer_applicant_maturity_age',
            status: 'UNDECIDED',
            label: '',
            value: '55',
            go: {
                max: '60'
            },
            deviation: {
                min: '20',
                max: '5'
            }
        }
    ]

    const [charts, setCharts] = useState([]);
    const [others, setOthers] = useState([]);
    const [rangeValue, setRangeValue] = useState(0);

    useEffect(() => {
        dummy?.forEach((data) => {
            if(getField(data?.code)?.chart){
                setCharts((prev) => ([...prev, data]));
            } else {
                setOthers((prev) => ([...prev, data]));
            }
        })
    }, []);

    const getField = useCallback((code) => {
        const obj = constFields?.filter((obj) => (obj?.code === code))?.[0];
        return obj;
    }, []);

    const getSubField = useCallback((code) => {

    }, []);

    const getChildField = useCallback((code) => {

    }, []);

    const statusColor = {
        PASS: 'success',
        FAIL: 'danger',
        UNDECIDED: 'warning'
    }

    const handleRange = (e) => {
        console.log(e)
        setRangeValue(e.target.value)
    }

    return (
        <div className='p-4'>
            <Row style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '15px', gridAutoRows: 'minmax(100px, auto)'}}>
                {charts?.map((obj, index) => {
                    const field = getField(obj?.code);
                    return <div key={`bre-lender-chart-${index}`}>
                        <div style={{fontSize: '12px', fontWeight: 'bold'}} className='d-flex flex-wrap justify-content-between mt-2'>
                            <div>
                                {field.label},&nbsp;
                                <span>
                                    Provided:&nbsp;
                                    {obj?.value}&nbsp;
                                </span>
                                <Badge color={statusColor?.[obj?.status]}>{obj?.status}</Badge>
                            </div>
                        </div>
                        <div className="range mt-2">
                            <input id={`range${index}`} type="range" min={obj?.go?.min || 0} max={obj?.go?.max || Number.MAX_SAFE_INTEGER} value={rangeValue} onChange={handleRange} />
                        </div>
                    </div>
                })}
            </Row>
            <Row style={{display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '15px', gridAutoRows: 'minmax(100px, auto)'}}>
                {dummy?.map((obj, index) => {
                    const field = getField(obj?.code);
                    return <React.Fragment key={`bre-lender-other-${index}`}>
                        <div 
                            style={{borderRadius: '5px', boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}
                            className='p-2'
                        >
                            <div style={{fontSize: '12px', fontWeight: 'bold'}} className='d-flex flex-wrap justify-content-between'>
                                <div>
                                    {field.label}&nbsp;<Badge color={statusColor?.[obj?.status]}>{obj?.status}</Badge>
                                </div>
                                <div>
                                    <i style={{cursor: 'pointer'}} className='ri-pencil-fill' />
                                </div>
                            </div>
                            <div>
                                <span>
                                    Provided:&nbsp;
                                </span>
                                <span>
                                    {obj?.value}
                                </span>
                            </div>
                            <div>
                                <div style={{fontSize: '12px', fontWeight: '400'}}>
                                    Go: <span>Minimum: {obj?.go?.min || '-'}</span>, <span>Maximum: {obj?.go?.max || '-'}</span>
                                </div>
                            </div>
                            <div>
                                <div style={{fontSize: '12px', fontWeight: '400'}}>
                                    Deviation: <span>Minimum: {obj?.deviation?.min || '-'}</span>, <span>Maximum: {obj?.deviation?.max || '-'}</span>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                })}
            </Row>
        </div>
    )
}

export default LenderMappingBRE;
